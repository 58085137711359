.MarkdownReader {
  @apply text-secondary-800;

  ul {
    @apply list-disc pl-7;
  }

  ol {
    @apply list-decimal pl-7;
  }
}
