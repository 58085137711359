.mdxeditor {
  @apply border border-secondary-200 rounded-lg overflow-hidden;

  --baseBg: rgb(var(--color-secondary-100));
  --baseBgActive: rgb(var(--color-light));
  --spacing-3: 1.25rem;
  --radius-base: 0.375rem;
  --radius-medium: 0;
  --baseTextContrast: rgb(var(--color-secondary-800));

  button {
    @apply rounded-md mr-1;
  }

  ul {
    @apply list-disc pl-7;
  }

  ol {
    @apply list-decimal pl-7;
  }
}
